import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/pic01.jpg'

const Policies = props => (
  <Layout>
    <Helmet>
      <title>Policies - Horovatin Consulting Ltd.</title>
      <meta name="description" content="Policies Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Policies</h1>
          </header>
          <span className="image main">
            <img
              src={pic01}
              alt="An Alberta prairie scene with golden fields under a blue sky with scattered clouds. Abandoned farm buildings and bare trees are on the horizon."
            />
          </span>
          <p>
            Let us help you define company policies and practices that are a fit
            for your company and your employees. Good Human Resources policies
            and practices allow you to make decisions and take action as needed
            to manage your people without standing in the way of progress.
          </p>
          <p>
            Let good policies become a guide for growth instead of a barrier.
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default Policies
